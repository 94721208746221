@import 'style/config';
@import "node_modules/sensible/mediaqueries";

.stage-teaser {
	width: 100%;
	position: relative;
	height: 490px;

	@include breakpoint(desktop) {
		height: 530px;
	}

	.image:before {
		top: 0;
		left: 0;
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		//background-image: linear-gradient(to bottom, $readability-shadow 0%, transparent 50%);
		//background: transparent url('../image/fade_top.png') repeat-x left top;
		z-index: 1;
	}

	.grid,
	.column {
		height: 100%;
	}

	.stage-teaser-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.stage-teaser-text {
		position: relative;
		z-index: 1;
	}

	.headline {
		text-shadow: 0 0 43px $shadow-color;
	}

	.read-more {

		.fake-button {
			@include fontsize(1.5em);
			padding: 0.75em;
			&.belimo-blue {
				background-color: $shaded-blue;
			}
			&.belimo-orange {
				background-color: $orange;
			}
			height: auto;
		}
	}

	.stage-teaser-headline {
		font-size: 51.2px;
		@include breakpoint(desktop) {
			font-size: 41.6px;
		}
		line-height: $headline-line-height;
		color: $white;
		text-shadow: 0 0 43px $shadow-color;

		box-sizing : border-box;
		display: block;
		font-weight: 700;
		margin-bottom: 28px;
	}
}
